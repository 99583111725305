/* Customize your main colors in :root variables */
:root {
  --main-background-color: #212121;
  --card-background-color: #212121;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#FFF;
  --title-text-color:#FF5800;
}
@font-face {
  font-family: "VTFMisterPixel";
  src: local("VTFMisterPixel"),
   url("./font/VTFMisterPixel.ttf") format("truetype");
  }
  @font-face {
    font-family: "Play-Regular";
    src: local("Play-Regular"),
     url("./font/Play-Regular.ttf") format("truetype");
    }
#root{
    min-height: 85vh;
}
body{
  background-color:var(--main-background-color) !important;
  background: url("img/background.png");
  background-size: cover !important;
  background-position: center bottom;
  background-repeat: no-repeat;
  font-family: "VTFMisterPixel";
  font-size: 1.1em;
  margin: 0;
  padding: 0px;
  overflow-x: hidden;
  position: relative;
}

h2, p{
  color: var(--main-text-color);
}
h3{
font-weight: normal;
font-size: 2.1em;
color: #558B01;
}
h4{
  font-weight: normal;
  font-size: 1.4em;
  font-family: "Play-Regular";
  color: ##fff;
  margin: 40px 0px 0px 0px !important;
}
.small-green{
  font-weight: normal;
  font-size: 1.2em !important;
  font-family: 'Play-Regular' !important;
  background-color: transparent !important;
  color: #558B01;
}
.img-monkey{
  height: 400px !important;;
}
@media (max-width: 1420px){
  .img-monkey{
    height: 250px !important;;
  }
}
.small-green-pixel{
  font-weight: normal;
  font-size: 1.2em !important;
  font-family: "VTFMisterPixel", cursive;
  background-color: transparent !important;
  color: #558B01;
  margin: 0px !important;
}
.flex-between{
  display: flex !important;
  justify-content: space-between !important;
}
.wallet-adapter-button {
  margin: auto !important;
  height: auto!important;
  line-height: initial!important;
  padding: 8px 32px!important;
  text-transform: uppercase !important;
}
button{
  background: transparent !important;
  color: white !important;
  border-radius: 60px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "VTFMisterPixel" !important;
  border: solid 2px white !important;
  box-shadow : none !important;
  transition: all 0.4s ease-out!important;
  padding: 8px 32px!important;
  height: auto!important;
  line-height: initial!important;
  text-transform: uppercase !important;
}
button:hover {
  background: transparent!important;
  border-color: #FF5800!important;
  cursor: pointer!important;
  transform: scale(0.94)!important;
}
.MuiButton-label {
  width: 100%!important;
  display: inherit;
  align-items: center!important;
  justify-content: center!important;
  margin: auto;
  text-transform: uppercase !important;
}
.wallet-adapter-modal-title {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  margin-bottom: 27px;
  margin-top: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-family: "VTFMisterPixel";
}
.MuiLinearProgress-colorPrimary {
  background-color: rgba(255, 89, 0, 0.53)!important;
  border: none !important;
}
.footer-basic .copyright {
  margin-top:15px;
  text-align:left;
  font-size:13px;
  font-family:"Play-Regular";
  color:#fff;
  margin-bottom:0;
}
.footer-basic{
  background-color: #1F2C20;
  padding: 20px 60px;
  position: relative;
  width: 100%;
  bottom: 0;

}
@media (max-width: 769px){
  .footer-basic{
    background-color: #1F2C20;
    padding: 10px;
  
  }
}
.footer-basic .social{
  align-items:center;
  display: flex;
  margin-left: 40px;
}
.footer-basic .logo img{
  max-width: 200px;
}
.footer-basic img.icon{
  width: 30px !important;
  margin: 10px;
}
.footer-basic .flexbox{
  display: flex;
}
.link-normal{
  font-size:13px;
  font-family:"Play-Regular";
  color:#fff;
  text-decoration: none !important;
}
.link-normal:hover{
  font-size:13px;
  font-family:"Play-Regular";
  color:#FF5800!important;
  text-decoration: none !important;
}
.flicker-1 {
	-webkit-animation: flicker-1 2s linear infinite both;
	        animation: flicker-1 2s linear infinite both;
}
/**
 * ----------------------------------------
 * animation flicker-1
 * ----------------------------------------
 */
@-webkit-keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}
@keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}